<template>
  <div>
    <div class="header">
      <img :src="backTitle" />
    </div>
    <div class="contents">
      <div class="background"></div>
      <v-container>
        <slot name="tagpage"></slot>
        <div class="contentBackground">
          <v-row class="newsContent" v-for="(con, y) in listItems" :key="y">
            <div class="tag">
              <p>{{ $t(con.topTag) }}</p>
            </div>
            <v-col class="newsImage" cols="12" md="6" sm="6">
              <img :src="con.image" />
            </v-col>
            <v-col class="newsIntro" cols="12" md="6" sm="6">
              <div class="top">
                <p class="titleName">{{ translate(con.title) }}</p>
                <img src="/static/img/news/headerLine.png" />
                <p class="subtitleName">{{ con.subtitle }}</p>
              </div>
              <div class="bottom">
                <router-link :to="con.href" class="text-decoration-none">
                  {{ con.button }}
                </router-link>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentComp",
  props: {
    backTitle: {
      type: String,
    },
    listItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    translate(text) {
      const reg = /^[a-zA-Z]+\.p\d+$/; // 匹配表格中的多语言字段
      if (reg.test(text)) {
        return this.$t(text);
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/img/common/contentBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.header {
  position: relative;
  width: 100%;
  max-height: 500px;
  z-index: 3;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.container {
  max-width: 1200px;
}
.contentBackground {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
.flexBox {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 70px;
}
.inline-display {
  padding: 20px 0px 15px 5px;
  .item {
    position: relative;
    display: inline-block;
    width: 230px;
    height: 60px;
    padding: 15px 0px 5px 5px;
    color: #504b93;
    font-size: 24px;
    background-image: url("/static/img/home/middle/titleSelect_nor.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-weight: 900;
    cursor: pointer;
  }
  .item.active {
    color: #ffffff;
    -webkit-text-stroke: 2px #654a93;
    background-image: url("/static/img/home/middle/titleSelected.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    font-weight: 100;
  }
}
.newsContent {
  height: 350px;
  margin-top: 30px;
  background-color: #38357d;
  position: relative;
  .tag {
    position: absolute;
    text-align: center;
    width: 177px;
    height: 44px;
    top: -10px;
    right: -10px;
    background-image: url("/static/img/news/tagImage.png");
    background-repeat: no-repeat;
    background-size: cover;
    p {
      color: #ffffff;
      position: relative;
      margin: 10px auto;
    }
  }
  .newsImage {
    max-width: 480px;
    max-height: 326px;
    overflow: hidden;
    img {
      padding-top: 20px;
      margin: 0px auto;
      width: 100%;
    }
  }
  .newsIntro {
    max-width: 58.3333%;
    flex: 0 0 58.333%;
    margin-left: 10px;
    margin-top: 60px;
    .top {
      text-align: left;
      .titleName {
        color: #ffffff;
        font-size: 40px;
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: 0.2rem;
      }
      .subtitleName {
        font-size: 30px;
        color: #7d8dfd;
      }
    }
    .bottom {
      a {
        display: block;
        width: 216px;
        height: 52px;
        line-height: 52px;
        background-image: url("/static/img/home/bottom/roleButton.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 auto;
        text-align: center;
        color: #3e1d05;
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flexBox {
    justify-content: center;
    flex-wrap: wrap;
  }
  .inline-display {
    .item {
      width: 180px;
    }
  }
  .newsContent {
    .newsIntro {
      max-width: 50%;
      flex: 0 0 50%;
      .top {
        .titleName {
          font-size: 30px;
        }
        .subtitleName {
          font-size: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .contentBackground {
    width: 450px;
    margin: 0 auto;
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .newsContent {
      height: auto;
      .newsImage {
        img {
          margin: 0;
        }
      }
      .newsIntro {
        margin-top: 0;
        max-width: 90%;
        .top {
          .titleName {
            font-size: 25px;
          }
        }
        .bottom {
          a {
            font-size: 25px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .flexBox {
    text-align: center;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .contentBackground {
    width: 380px;
    .newsContent {
      .newsImage {
        flex: 0;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .header {
    img {
      min-height: 160px;
      width: 150%;
      margin-left: -70px;
    }
  }
  .flexBox {
    text-align: center;
    justify-content: space-between;
  }
  .inline-display {
    .item {
      width: 150px;
      font-size: 20px;
    }
  }
  .contentBackground {
    width: 320px;
    .newsContent {
      .tag {
        width: 127px;
        height: 33px;
        p {
          margin: 5px auto;
        }
      }
      .newsImage {
        flex: 0;
      }
    }
  }
}
</style>
